import { Switch, useHistory, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SecureRoute, Security } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import React, { useEffect, useLayoutEffect, memo, useState, useMemo, useCallback } from 'react';
import i18n from 'i18next';
import clsx from 'clsx';
import Spinner from 'react-bootstrap/Spinner';

import ErrorHandler from 'src/components/app/ErrorHandler';
import Agreements from 'src/components/app/Agreements';
import LoginCallback from 'src/pages/LoginCallback';
import Login from 'src/pages/Login';
import EnrollmentRequiredRoute from 'src/components/app/EnrollmentRequiredRoute';
import { getAppMarketTranslation } from 'src/helpers/getAppMarketTranslation';

import TelemetryProvider from './appInsightsTelemetryProvider';

import { endpointUrlPrefixActionCreator, anI18NextLibActionCreator } from '../store/app/app.slice';
import { getValuesFromLocalStorage } from '../helpers/SessionHelper';
import { ILocalStorageValues } from '../store/root.types';
import { restoreStateFromLocalStorage } from '../store/root.actions';
import { setWindowSize } from '../store/ui/ui.slice';
import { RootState } from '../store/store';
import { fetchProfile } from '../store/profile/profile.thunks';
import Header from '../components/app/Header';
import ConstantsHelper from '../helpers/ConstantsHelper';
import MenuHelper from '../helpers/MenuHelper';
import SystemHelper from '../helpers/SystemHelper';
import UiHelper from '../helpers/UiHelper';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import AnI18NextLibHelper from '../helpers/AnI18NextLibHelper';
import { useDocumentOrientation } from '../hooks/useDocumentOrientation';
import * as pages from '../pages';
import 'bootstrap/dist/css/bootstrap.css'; // Import precompiled Bootstrap css
import './App.scss';
import OktaMockForm from '../pages/oktaMockForm';

let appInsights: any = null;

const aiProps = {
    after: (aiObject: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        appInsights = aiObject;
    },
};

const bemBlockName = 'app';

const useAuthRequired = () => {
    const onAuthRequired = useMemo(() => {
        return () => {
            if (!window.location.pathname.includes('/oktaMock')) {
                window.location.assign('/oktaMock');
            }
        };
    }, []);

    return onAuthRequired;
};

const redirectToHcp = () => {
    const hcpAppUrl = SystemHelper?.getConfig(
        ConstantsHelper.urlParamsConstants.hcpAppUrl.urlKey,
        ConstantsHelper.urlParamsConstants.hcpAppUrl.settingsKey,
        ConstantsHelper.urlParamsConstants.hcpAppUrl.runtimeKey,
        false,
        true
    );
    window.location.replace(hcpAppUrl);
};

const Routes = memo(function Routes() {
    const routes = useMemo(() => {
        return MenuHelper.MenuEntries.filter((e) => e.path).map((e: any) => {
            return <EnrollmentRequiredRoute key={e.path} component={e.component} exact={e.exact} path={e.path} />;
        });
    }, []);

    return <>{routes}</>;
});

function App() {
    const history = useHistory();
    const dispatch = useDispatch();

    const anI18Nextlib = useSelector((state: RootState) => state.app.anI18Nextlib);
    const allLoaded = useSelector((state: RootState) => state.app.allLoaded);
    const isPolling = useSelector((state: RootState) => state.app.isPolling);
    const loadingSemaphore = useSelector((state: RootState) => state.app.loadingSemaphore);
    const errors = useSelector((state: RootState) => state.app.errors);
    const profileData = useSelector((state: RootState) => state.profile.data);
    const isMenuOpen = useSelector((state: RootState) => state.ui.isMenuOpen);

    const isPauseItEnabled = useMemo(() => SystemHelper.IsPauseItEnabled(), []);

    const { oktaAuth, endpointPwdUrlPrefix, hcpAppUrl, endpointPdfUrlPrefix } = useMemo(() => MenuHelper.Init(), []);

    const [readyToGo, setReadyToGo] = useState(UiHelper.IsSiteReadyToGo(allLoaded, errors, profileData.receivedData));
    const documentOrientation = useDocumentOrientation();

    const onAuthRequired = useAuthRequired();

    const restoreOriginalUri = useCallback(
        async (_oktaAuth: any, originalUri: string) =>
            history.replace(toRelativeUrl(originalUri || '/', window.location.origin)),
        [history]
    );

    useEffect(() => {
        let pollingTimer: ReturnType<typeof setInterval>;
        if (isPolling) {
            pollingTimer = setInterval(() => {
                dispatch(fetchProfile(null));
            }, 3000);
        }

        return () => {
            if (pollingTimer) {
                clearInterval(pollingTimer);
            }
        };
    }, [dispatch, isPolling]);

    useEffect(() => {
        const valuesFromLocalStorage: ILocalStorageValues = getValuesFromLocalStorage();
        const browserInfo = SystemHelper.GetBrowserInfo();
        const isProduction = SystemHelper.IsProd();
        const appLanguage = getAppMarketTranslation();

        i18n.changeLanguage(appLanguage);

        dispatch(restoreStateFromLocalStorage(valuesFromLocalStorage));
        dispatch(endpointUrlPrefixActionCreator({ endpointPwdUrlPrefix, hcpAppUrl, endpointPdfUrlPrefix }));
        dispatch(anI18NextLibActionCreator({ anI18Nextlib: AnI18NextLibHelper.loadLang(appLanguage) }));

        MenuHelper.MapMenuComponents(profileData, pages);
        SystemHelper.AppInsightsEvent(
            anI18Nextlib,
            'App',
            `Mounted -- environment:  ${isProduction ? 'Prod' : 'Non-prod'} -- browser:  ${browserInfo.tag} is ${
                browserInfo.supported ? 'supported' : 'unsupportred'
            }`
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useLayoutEffect(() => {
        const updateWindowSize = () => {
            dispatch(
                setWindowSize({
                    height: window.innerHeight,
                    width: window.innerWidth,
                })
            );
        };

        window.addEventListener('resize', updateWindowSize);
        updateWindowSize();

        return () => window.removeEventListener('resize', updateWindowSize);
    }, [dispatch]);

    useEffect(() => {
        const newReadyToGo = UiHelper.IsSiteReadyToGo(allLoaded, errors, profileData.receivedData);

        if (!readyToGo && newReadyToGo) {
            MenuHelper.MapMenuComponents(profileData, pages);
            setReadyToGo(true);
        }
    }, [allLoaded, readyToGo, errors, profileData]);

    const totalCallsToConsiderFetching = useMemo(() => (isPolling ? 2 : 1), [isPolling]);
    const isFetching = useMemo(
        () => loadingSemaphore >= totalCallsToConsiderFetching,
        [loadingSemaphore, totalCallsToConsiderFetching]
    );
    const shouldDisableContent = useMemo(() => isFetching || isMenuOpen, [isFetching, isMenuOpen]);

    if (SystemHelper.IsHcpUserSession(oktaAuth, isPauseItEnabled)) {
        redirectToHcp();
    }

    return (
        <TelemetryProvider {...aiProps}>
            <div
                className={clsx(styleGeneral.main, styleGuide.dashboard)}
                dir={documentOrientation}
                data-testid={bemBlockName}
            >
                {readyToGo ? (
                    <>
                        <div
                            className={clsx({
                                [styleGeneral.show]: shouldDisableContent,
                                [styleGeneral.layerTopmost]: shouldDisableContent,
                                [styleGeneral.hide]: !shouldDisableContent,
                            })}
                            data-testid={`${bemBlockName}__overlay`}
                        />

                        {isFetching && (
                            <Spinner
                                className={styleGeneral.spinner}
                                data-testid={`${bemBlockName}__fetching-after-initial-fetch-spinner`}
                                animation="border"
                                role="status"
                            />
                        )}
                    </>
                ) : (
                    <Spinner className={styleGeneral.spinner} animation="border" role="status" data-testid="spinner" />
                )}

                <Switch>
                    <Route exact path="/oktaMock" render={() => <OktaMockForm oktaAuth={oktaAuth} />} />
                </Switch>

                <div className="App">
                    {allLoaded && (
                        <Security
                            oktaAuth={oktaAuth}
                            restoreOriginalUri={restoreOriginalUri}
                            onAuthRequired={onAuthRequired}
                        >
                            {readyToGo && <Header />}

                            <Switch>
                                <Route path="/insuletid/callback" component={Login} />
                                <Route path="/login/callback" component={LoginCallback} />
                                <Route path="/error" component={pages.Error} />

                                <SecureRoute path="/">
                                    <ErrorHandler>
                                        <Agreements>{readyToGo && <Routes />}</Agreements>
                                    </ErrorHandler>
                                </SecureRoute>

                                <Redirect to="/error" />
                            </Switch>
                        </Security>
                    )}
                </div>
            </div>
        </TelemetryProvider>
    );
}

export default App;
